import React from "react";
import Logo from "../img/logo_oudan_sharing.png";

const Header = (props) => {
    return (
        <header className="header">
            <img src={Logo} alt="Oudan sharing logo" width="15%"/>
        </header>
    )
};

export default Header;